import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/common/Button';
import { FullScreenDialog } from '../components/common/FullScreenDialog';
import {
  languages,
  SelectedLanguage,
} from '../components/common/LanguageDropdown';
import { Logo } from '../components/common/Logo';
import { Row } from '../components/common/Row';
import { Stack } from '../components/common/Stack';
import { Form } from '../components/form/Form';
import { InputField } from '../components/form/InputField';
import { ToggleField } from '../components/form/ToggleField';
import { useApiQuery } from '../hooks/useApi';
import { useFormSubmission } from '../hooks/useFormSubmission';
import { useTranslation } from '../I18nProvider';
import { color, space } from '../tokens';
import { useSetLogin } from '../UserProvider';
import {
  registerSubcontractorInvite,
  RegisterSubcontractorInviteArgs,
  UserLocale,
} from '../utils/api';
import { Terms } from './Terms';

const DropdownWrapper = styled.div`
  position: relative;
  margin-bottom: ${space[4]};
`;

const Selector = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: ${color.primary};
  cursor: pointer;
  padding: 5px;
  text-align: center;
`;

const Dropdown = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: calc(100% + 5px);
  z-index: 1;
`;

const Option = styled.div`
  color: ${color.primary};
  cursor: pointer;
  padding: 10px 10px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export function RegisterSubcontractorInvite() {
  const { token } = useParams();
  const t = useTranslation();
  const setLogin = useSetLogin();
  const invite = useApiQuery('getSubcontractorInvite', token!);

  const [isOpen, setIsOpen] = useState(false);
  const storedLocale = window.localStorage.getItem('locale');

  const defaultLanguage: SelectedLanguage = {
    locale: 'en',
    name: 'English',
  };
  const locale: UserLocale = (
    storedLocale ? JSON.parse(storedLocale) : defaultLanguage.locale
  ) as UserLocale;
  const [selectedLanguage, setSelectedLanguage] = useState<SelectedLanguage>({
    locale,
    name:
      languages.find((l) => l.locale === locale)?.name || defaultLanguage.name,
  });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectorRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const reload = () => window.location.reload();
  const selectLanguage = (language: SelectedLanguage) => {
    window.localStorage.setItem('locale', JSON.stringify(language.locale));
    reload();
    setSelectedLanguage(language);
    setIsOpen(false);
  };

  const handleOutsideClick = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !selectorRef.current?.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const { form, register } = useFormSubmission<RegisterSubcontractorInviteArgs>(
    {
      async onSubmit(data) {
        const res = await registerSubcontractorInvite(token!, {
          ...data,
          locale: selectedLanguage?.locale || 'en',
        });
        setLogin(res);
        window.location.reload();
      },
    }
  );

  return (
    <FullScreenDialog>
      <Logo />
      {invite.isSuccess ? (
        <Form {...form}>
          <Stack>
            <p>{t.inviteToSignUpMessage('')}</p>
            <InputField
              register={register}
              placeholder={t.companyName}
              name="companyName"
              type="text"
              required
            />
            <InputField
              register={register}
              placeholder={t.firstName}
              name="firstName"
              type="text"
              required
            />
            <InputField
              register={register}
              placeholder={t.lastName}
              name="lastName"
              type="text"
              required
            />
            <InputField
              register={register}
              placeholder={t.password}
              name="password"
              type="password"
              required
            />
            <DropdownWrapper>
              <Selector ref={selectorRef} onClick={toggleDropdown}>
                {selectedLanguage?.locale.toUpperCase()}
              </Selector>
              {isOpen && (
                <Dropdown ref={dropdownRef}>
                  {languages.map((language: SelectedLanguage) => (
                    <Option
                      key={language.locale}
                      onClick={() => selectLanguage(language)}
                    >
                      {language.name} ({language.locale.toUpperCase()})
                    </Option>
                  ))}
                </Dropdown>
              )}
            </DropdownWrapper>
            <ToggleField register={register} name="acceptTerms">
              {t.acceptTerms}
            </ToggleField>
            <Terms />
            <Button type="submit" variant="primary">
              {t.signUp}
            </Button>
          </Stack>
        </Form>
      ) : (
        <Row justify="center">{t.invitationExpired}</Row>
      )}
    </FullScreenDialog>
  );
}
