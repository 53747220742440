import React from 'react';

export const pl = {
  aborted: 'Przerwano',
  accelerationDescription:
    'Współczynnik przyspieszenia używany do obliczania krzywej.',
  accelerationNote:
    'Ten współczynnik musi być wystarczająco wysoki, aby osiągnąć ustawione prędkości, w przeciwnym razie drzwi nie osiągną żądanej prędkości.',
  accelerationTitle: 'Przyspieszenie',
  acceptTerms: 'Akceptuję warunki użytkowania',
  add: 'Dodaj',
  addBuilding: 'Dodaj Budynek',
  addLift: 'Dodaj Windę',
  addressHint:
    'Wprowadź nazwę firmy lub budynku, aby uzyskać sugestie dotyczące adresu i obrazu.',
  adjustCameraSettings:
    'Możesz także dostosować ustawienia kamery za pomocą menu rozwijanego.',
  admin: 'Administrator',
  alreadyHasSubcontractorsError:
    'Jako podwykonawca nie możesz mieć podwykonawców. Usuń istniejących podwykonawców przed zaakceptowaniem tego zaproszenia.',
  all: 'Wszystko',
  allBuildings: 'Wszystkie Budynki',
  allBuildingsSubscription: 'Ze wszystkich budynków',
  alphabetical: 'Alfabetycznie',
  ambientTemperature: 'Temperatura w Skrzynce',
  assetNumber: 'ID Windy',
  assetNumberHint:
    'Wprowadź ID (np. numer instalacji/urządzenia) windy, jeśli masz go pod ręką.',
  auto: 'Automatycznie',
  back: 'Wstecz',
  backToDashboard: 'Wróć do Panelu',
  belt_tension: 'Napięcie Paska',
  belt_tension_actions:
    'Dostosuj napięcie paska zgodnie z instrukcją instalacji.',
  beltclamp: 'Mocowanie Paska',
  belt_tension_description: 'Napięcie paska jest zbyt wysokie/niskie.',
  bottom: 'Dół',
  bottomToTop: 'Od Dołu do Góry',
  boxLinkedToOtherLift: (lift: string, building: string) =>
    `Skrzynka jest powiązana z ${lift} w ${building}. Kontynuować?`,
  boxNotReady: 'Wybrana skrzynka nie jest gotowa.',
  boxOfflineHint:
    'Jeśli chcesz zaktualizować jedną ze skrzynek wymienionych poniżej, najpierw podłącz ją do internetu.',
  boxScannedNoIdFound: 'Skrzynka zeskanowana, ale nie znaleziono ID.',
  boxSoftware: 'Oprogramowanie',
  boxSoftwareStatusError:
    'Nie można pobrać statusu skrzynek: sprawdź połączenie internetowe skrzynki i spróbuj ponownie. Jeśli problem będzie się powtarzał, skontaktuj się z przedstawicielem Wittur.',
  boxSoftwareTitle: 'Oprogramowanie Skrzynki',
  boxSoftwareUpdateError:
    'Nie udało się zaktualizować oprogramowania skrzynki: sprawdź połączenie internetowe skrzynki i spróbuj ponownie. Jeśli problem będzie się powtarzał, skontaktuj się z przedstawicielem Wittur.',
  box_id: 'ID Skrzynki',
  brakeParDescription:
    'Ze względu na ustawienie tego parametru wpływa on na opóźnienie i pełzanie do końca otwarcia/zamknięcia (wstępnie ustawione na „5”).',
  brakeParNote:
    'Możliwe ustawienia: „0”..„9”, ale najwcześniejsze hamowanie osiąga się przy parametrze „0”, a najpóźniejsze opóźnienie przy parametrze „9”.',
  brakeParTitle: 'Parametr Hamowania',
  buffer: 'Bufor',
  building: 'Budynek',
  buildingHasNoElevator: 'Ten budynek nie ma wind.',
  buildingName: 'Nazwa',
  buildings: 'Budynki',
  buildingsWithoutValidAddress: 'Budynki bez poprawnego adresu',
  bushingClose: 'Zamknięcie Tulei',
  bushingOpen: 'Otwarcie Tulei',
  cablechain: 'Łańcuch Kablowy',
  cableTensorSpring: 'Sprężyna Napinacza Kabla',
  callFloorsInstruction: (
    <>
      Otwórz drzwi i <b>zadzwoń na wszystkie inne piętra</b>. Wyjdź z kabiny i
      kliknij "Kontynuuj".
    </>
  ),
  cameraMode: 'Tryb kamery',
  cancel: 'Anuluj',
  car: 'Kabina',
  carDoor: 'Drzwi Kabiny',
  carDoorCounter: 'Licznik Cykli Drzwi Poprzedniej Elektroniki',
  carDoorModified: 'Drzwi kabiny zostały zmodyfikowane',
  cd_counter_rollers_installation: 'Rolki Licznika',
  cd_counter_rollers_installation_actions:
    'Dla każdych drzwi przystankowych upewnij się, że rolki można łatwo obracać ręką i że ich odległość od szyny nie jest zbyt duża. Jeśli to nastąpi, poluzuj rolki i dostosuj je tak, aby były jak najbliżej szyny, ale jej nie dotykały i można je było łatwo obracać ręką. Jeśli musisz zdemontować sprzęgło, postępuj zgodnie z instrukcjami instalacji.',
  cd_counter_rollers_installation_description:
    'Rolki licznika CD nie są poprawnie zamontowane.',
  cd_roller: 'Rolki CD',
  chooseFloorOrCardoor: 'Wybierz Piętro / Drzwi Kabiny',
  choosePartNumber: 'Wybierz Numer Części',
  chooseReason: 'Wybierz powód (opcjonalne)',
  chooseStartingFloorInstructions:
    'Wybierz piętro początkowe dla biegu testowego. Piętro początkowe może być najwyższym lub najniższym piętrem.',
  clearDriveErrors: 'Wyczyść',
  close: 'Zamknij',
  closeDoorInstructions: (floor: string) => (
    <>
      Udaj się na <b>{floor}</b> piętro. Upewnij się, że drzwi kabiny są
      zamknięte przed kontynuowaniem.
    </>
  ),
  closeForcePotiDescription:
    'Ustaw limit siły używanej przy otwieraniu i zamykaniu drzwi.',
  closeForcePotiNote:
    'Regulowane za pomocą potencjometru na elektronice. Na nowszych elektronach takich jak MIDIV2 można ustawić tutaj w trybie serwisowym, jeśli jest to konieczne.',
  closeForcePotiTitle: 'Limit Siły Zamknięcia',
  closeHoldForceDescription: 'Siła stosowana w pozycji zamknięcia.',
  closeHoldForceNote:
    'Siła ta powinna wynosić od 50N do 70N. Zbyt niska siła może powodować niechciane otwieranie sprzęgła. Zbyt wysoka siła nagrzewa silnik niepotrzebnie i może prowadzić do pracy w trybie redukcji.',
  closeHoldForceTitle: 'Siła Utrzymania Zamknięcia',
  closeSpeedDescription:
    'Maksymalna prędkość drzwi w kierunku zamknięcia. Krzywa zamykania jest obliczana w celu osiągnięcia tej ustawionej prędkości, jeśli to możliwe.',
  closeSpeedNote:
    'Rzeczywista prędkość drzwi jest również związana z przełącznikami DIP prędkości napędu. Ustawienie na prędkość 4 oznacza 100% wartości 2.',
  closeSpeedTitle: 'Prędkość Zamknięcia',
  closing_device_failure: 'Urządzenie Zamykania',
  closing_device_failure_actions:
    'Sprawdź, czy urządzenie zamykające dla drzwi przystankowych działa poprawnie we wszystkich jego elementach (spirator, sprężyna zamykająca i ciężar zamykający). Drzwi przystankowe muszą się samodzielnie zamykać z każdej pozycji drzwi.',
  closing_device_failure_description:
    'Urządzenie zamykające nie jest prawidłowo zainstalowane. Siła potrzebna do zamknięcia drzwi jest zbyt duża.',
  companyName: 'Nazwa Firmy',
  completed: 'Zakończono',
  componentDetails: 'Szczegóły Komponentów',
  componentDetailsHint:
    'Dla niektórych komponentów istnieje wiele opcji numerów części, które należy wybrać. Jest to ważna informacja dla funkcji konserwacji zapobiegawczej. Kliknij wymienione elementy, aby wybrać rzeczywiście zainstalowaną część.',
  confirm: 'Potwierdź',
  confirmDeleteBuilding: 'Czy na pewno chcesz usunąć ten budynek?',
  confirmDeleteErrorHistory: 'Czy na pewno chcesz usunąć tę historię błędów?',
  confirmDeleteFavoriteBuilding:
    'Czy na pewno chcesz usunąć ten budynek z ulubionych?',
  confirmDeleteLift: 'Czy na pewno chcesz usunąć tę windę?',
  confirmInvite: 'Potwierdź i Połącz',
  connectionErrorMessage:
    'Nie można pobrać danych z powodu problemu z połączeniem. Spróbuj ponownie później.',
  continue: 'Kontynuuj',
  contractorBuilding: 'Budynek Zleceniodawcy',
  contractorBuildings: 'Budynki Zleceniodawców',
  contractors: 'Zleceniodawcy',
  couplerelease: 'Zwolenie Sprzęgła',
  couplerFriction: 'Tarcie Sprzęgła',
  couplerMovementParameter: 'Parametr Ruchu Sprzęgła',
  couplerSpeedDescription:
    'Ta wartość definiuje maksymalną prędkość paska w zdefiniowanym obszarze sprzęgła.',
  couplerSpeedTitle: 'Prędkość Sprzęgła',
  couplerWitdhDescription:
    'Ta wartość definiuje odległość paska w obszarze sprzęgła. Ta wartość jest wynikiem procedury uczenia.',
  couplerWitdhTitle: 'Szerokość Sprzęgła',
  currentVersion: 'Obecna Wersja',
  couplerwidth: 'Szerokość Sprzęgła',
  date: 'Data',
  delete: 'Usuń',
  deleteErrorHistory: 'Usuń historię błędów',
  directConnection: (
    <>
      Połącz się z Wi-Fi skrzynki ELEVATORSENSE, skanując kod QR wydrukowany na
      skrzynce za pomocą aplikacji aparatu w telefonie.
    </>
  ),
  disconnectProgrammingTool: 'Odłącz Narzędzie Programowania Bezprzewodowego',
  divpulley: 'Koło Pasowe',
  documentation: 'Dokumentacja',
  doorContact: 'Styk Drzwiowy',
  doorCycleCounter: 'Licznik Cyklów Drzwi',
  doorCycleError1: 'Nie odebrano danych cykli drzwi.',
  doorCycleError2: 'Ruch cykli drzwi został zakłócony.',
  doorCycleError4: 'Wykryto ponowne otwarcie drzwi.',
  doorCycleError5: 'Wykryto ponowne zamknięcie drzwi.',
  doorCycleError7: 'Nie odebrano żadnych prawidłowych cykli drzwi.',
  doorCycles: 'Cykl Drzwi',
  doorFriction: 'Tarcie Drzwi',
  doorInstallationDate: 'Data Instalacji Drzwi',
  doorIsClosed: 'Tak, drzwi są zamknięte',
  doorModel: 'Model Drzwi',
  doorModelNotSupported:
    'Wybrany model drzwi nie jest dostępny dla konserwacji zapobiegawczej.',
  doorMovementParameter: 'Parametr Ruchu Drzwi',
  doorPosition: 'Pozycja Drzwi',
  doorSerialNumber: 'Numer Seryjny Drzwi',
  doorType: 'Typ Drzwi',
  doorwidth: 'Szerokość Drzwi',
  downloadCertificate: 'Pobierz Certyfikat',
  downloadLicense: 'Pobierz wszystkie licencje jako CSV',
  downloadRequestedExtendedWarranty:
    'Pobierz wszystkie wnioski o przedłużenie gwarancji',
  drive_motor_noise: 'Hałas Silnika Napędu',
  driveesn: 'ESN',
  drivesoftware: 'Firmware',
  editBuilding: 'Edytuj Budynek',
  editLift: 'Edytuj Windę',
  elecBoard: 'Płyta Elektryczna',
  elevatorIdNotFound: (
    <>
      Aby zażądać przedłużenia gwarancji, musisz wprowadzić numer seryjny drzwi
      w ustawieniach windy. <b>Kliknij tutaj, aby przejść do ustawień.</b>
    </>
  ),
  elevatorInstallationDate: 'Data Instalacji Windy',
  elevatorUsageType: 'Typ Użytkowania Windy',
  email: 'E-mail',
  encoderTemperature: 'Temperatura Enkodera',
  error: 'Błąd',
  errorDescription: 'Opis Błędu',
  errorEarlierThanInstallationDate:
    'Nie można wprowadzić daty wcześniejszej niż data instalacji.',
  errorInvalid: 'Wprowadź prawidłową wartość.',
  errorInvalidOperation: 'Nieprawidłowa operacja',
  errorMessages: 'Wiadomości Błędów',
  errorMessagesDescription: 'Wyświetlanie zapisanych wiadomości błędów.',
  errorNotFound: 'Nie znaleziono zasobu',
  errorRetryCamera:
    'Nie udało się uzyskać dostępu do kamery. Ponawianie, strona zostanie odświeżona...',
  errorRequired: 'To pole jest wymagane.',
  errorUnableToAccessCamera:
    'Nie udało się uzyskać dostępu do kamery po wielokrotnych próbach. Odśwież stronę lub sprawdź uprawnienia kamery.',
  errorUnknownOrForbiddenBox:
    'Skrzynka jest niedostępna. Jeśli potrzebujesz pomocy, skontaktuj się z obsługą Wittur.',
  errors: 'Błędy',
  errorsEmpty: 'Brak wiadomości.',
  errorsEmptyHint: 'Sprawdź swoje filtry i być może włącz dodatkowe typy.',
  extendWarranty: 'Przedłuż Gwarancję',
  fatalError: 'Krytyczny Błąd',
  fatalErrors: 'Krytyczne Błędy',
  favorite: 'Ulubione',
  favorites: 'Ulubione',
  figureAdjustment: 'Regulacja',
  figureAugustaEVO: 'Przykład Augusta EVO',
  figureClosingWeight: 'Ciężar Zamykania',
  figureEco: 'ECO+',
  figureHydra: 'Przykład Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'Nie OK',
  figureOk: 'OK',
  figureSGV: 'Przykład SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Sprężyna Zamykania',
  firstName: 'Imię',
  floor: 'Piętro',
  floors: 'Piętra',
  followingBoxScanned: 'Zeskanowano następującą skrzynkę',
  forgotPassword: 'Zapomniałeś hasła?',
  forgotPasswordText: 'Wprowadź swój adres e-mail, aby zresetować hasło.',
  groundFloor: 'Parter',
  home: 'Strona Główna',
  incompleteElevatorSettings:
    'Aby użyć konserwacji zapobiegawczej, potrzebujemy więcej informacji o tej windzie. Przejdź do ustawień windy i uzupełnij brakujące informacje.',
  initialized: 'Zainicjalizowano',
  installationControl: 'Kontrola Instalacji',
  installationControlDescription:
    'Wykonuj automatyczne kontrole w celu wykrycia powszechnych problemów z instalacją.',
  installationControlExecutedBy: 'Wykonane przez',
  installationControlHeadline: 'Ostatni Status Instalacji',
  installationControlHistory: 'Historia Biegu',
  installationControlHistoryEmpty: 'Brak zapisanych biegów instalacyjnych',
  installationControlInstructions: 'Pokaż Instrukcje',
  installationControlSubheadline: 'Podsumowanie wszystkich biegów',
  installationDateErrorMessage:
    'Nie można wprowadzić wcześniejszej daty instalacji drzwi niż daty instalacji windy',
  instantSubscription: 'Natychmiastowe powiadomienie',
  instantSubscriptionExplanation: (
    <>
      <b>Natychmiastowe powiadomienie</b> to powiadomienie wysyłane natychmiast
      po wystąpieniu krytycznego błędu w windzie. Krytyczne błędy to błędy,
      które mogą prowadzić do natychmiastowego uszkodzenia windy.
    </>
  ),
  invitationExpired: 'Link zaproszenia jest już nieaktywny.',
  inviteAsManager: 'Użytkownik może zapraszać innych użytkowników.',
  inviteCompanyWithSubcontractorsError:
    'Firma, którą próbujesz zaprosić, już posiada podwykonawców i nie może zostać dodana jako podwykonawca. Muszą usunąć istniejących podwykonawców lub wybrać inną firmę.',
  inviteCoworkers: 'Zaproś współpracowników',
  inviteSubcontractor: 'Zaproś Podwykonawcę',
  inviteToBeSubcontractorMessage: (companyName: string) =>
    `${companyName} zaprosiła Cię do bycia ich podwykonawcą w ElevatorSense.`,
  inviteToSignUpMessage: (companyName: string) =>
    `Firma ${companyName} zaprosiła Cię do rejestracji w ElevatorSense jako ich podwykonawca. Proszę wypełnij formularz, aby się zarejestrować.`,
  ipaddress: 'Adres IP',
  joinText: (name: string) => (
    <>
      Zarejestruj się, aby dołączyć do organizacji <i>{name}</i>.
    </>
  ),
  landingDoor: 'Drzwi Przystankowe - Piętro',
  landing_lock_roller_adjustment: 'Regulacja Rolek Zamek Przystankowy',
  landingCarDoorSpareParts: 'Części Zapasowe Drzwi Przystankowych/Kabiny',
  lastError: 'Ostatni Błąd',
  lastExchangeDate: 'Data Ostatniej Wymiany',
  lastMaintenanceDate: 'Data Ostatniej Konserwacji',
  lastMaintenanceScope: 'Zakres Ostatniej Konserwacji',
  lastMaintenanceScopeHint:
    'Wybierz zakres konserwacji zalecany przez Wittur, który został faktycznie przeprowadzony w ostatniej dacie konserwacji.',
  lastName: 'Nazwisko',
  lastTimeDoorElectronicsChange: 'Data Wymiany Elektroniki Drzwiowej',
  latestSoftwarePackVersion: (version: string) =>
    `Wersja pakietu oprogramowania: ${version}`,
  ld_counter_rollers_installation: 'Rolki Licznika',
  ld_counter_rollers_installation_actions:
    'Dla każdych drzwi przystankowych upewnij się, że rolki można łatwo obracać ręką i że ich odległość od szyny nie jest zbyt duża. Jeśli to nastąpi, poluzuj rolki i dostosuj je tak, aby były jak najbliżej szyny, ale jej nie dotykały i można je było łatwo obracać ręką. Jeśli musisz zdemontować sprzęgło, postępuj zgodnie z instrukcjami instalacji.',
  ld_counter_rollers_installation_description:
    'Rolki licznika LD nie są poprawnie zamontowane.',
  ld_roller: 'Rolki LD',
  left: 'Lewo',
  licenseRequired:
    'Nie posiadasz ważnej licencji na ten produkt. Skontaktuj się ze swoim przedstawicielem handlowym Wittur, aby zakupić produkt ElevatorSense.',
  lift: 'Winda',
  liftMovementDistance: 'Ruch Windy',
  liftSetup: 'Konfiguracja Windy',
  liftSetupComplete: 'Konfiguracja zakończona.',
  liftSetupError: 'Odpowiednie dane są niekompletne lub brakujące.',
  liftSetupError40: 'Ruch windy nie odpowiada biegowi konfiguracji.',
  liftSetupIncomplete: 'Konfiguracja niekompletna.',
  liftSetupPending:
    'Proszę przeprowadzić bieg konfiguracji w celu początkowej konfiguracji ElevatorSense na swojej windzie. Bez biegu konfiguracji nie będziesz mógł korzystać z niektórych funkcjonalności produktów ElevatorSense lub wyniki produktów ElevatorSense mogą się różnić. Kliknij tutaj, aby przeprowadzić bieg konfiguracji.',
  liftSetupRepeat:
    'Bieg konfiguracji nie powiódł się. Proszę powtórzyć bieg, uwzględniając opis procesu.',
  lifts: 'Windy',
  linkBox: 'Połącz Skrzynkę',
  linkToWebshop: (link: string) => (
    <>
      Jeśli masz skrzynkę ElevatorSense i chcesz aktywować licencję, kliknij{' '}
      <a href={link} className="clickable-link">
        tutaj
      </a>
      .
    </>
  ),
  loading: 'Ładowanie',
  login: 'Zaloguj się',
  loginText: 'Zaloguj się na swoje konto.',
  logout: 'Wyloguj się',
  manageAccess: 'Zarządzaj Dostępem',
  manageElevators: 'Zarządzaj Windami',
  manageSharedBuildingsAndElevator:
    'Zarządzaj Budynkami/Windami Udostępnionymi',
  mandatory: 'Wykonaj Sprawdzenie Wymiany',
  manual: 'Ręcznie',
  maxElectronics: 'Maksymalna liczba wind',
  maxUsage: 'Maksymalne Użycie',
  members: 'Członkowie',
  misalignment_cd_vs_ld: 'Wyrównanie Drzwi/Sprzęgła',
  misalignment_cd_vs_ld_actions:
    'Dostosuj rolki zamka drzwi przystankowych. Prześwit pomiędzy łopatkami sprzęgła a rolkami musi być taki sam po lewej i prawej stronie.',
  misalignment_cd_vs_ld_description:
    'Sprzęgło drzwi kabinowych jest niewyrównane z rolkami zamka drzwi przystankowych.',
  misalignment_cd_vs_ld_sill_gap: 'Szczelina w Progu',
  misalignment_cd_vs_ld_sill_gap_actions:
    'Szczelina w progu po lewej i prawej stronie otworu drzwiowego nie jest równa. Jeśli występuje to na wszystkich piętrach, dostosuj pozycję progu drzwi kabinowych.',
  misalignment_cd_vs_ld_sill_gap_description:
    'Drzwi przystankowe nie są równoległe do drzwi kabinowych.',
  mode: 'Tryb',
  monitoring: 'Monitorowanie',
  motor: 'Silnik',
  motorTemperature: 'Temperatura Silnika',
  myBuildingsSubscription: 'Tylko z moich ulubionych',
  name: 'Nazwa',
  networkQuality: 'Jakość Sieci',
  newInstallationControl: 'Nowa Kontrola Instalacji',
  next: 'Następny',
  nextMaintenanceDate: 'Data następnej konserwacji',
  no: 'Nie',
  notAuthorizedToConfirmInviteError:
    'Nie jesteś uprawniony do potwierdzenia tego zaproszenia, ponieważ nie jest przeznaczone dla Twojego zalogowanego użytkownika.',
  noBoxLinked:
    'Winda nie jest jeszcze połączona ze skrzynką ELEVATORSENSE. Aby korzystać ze wszystkich funkcji aplikacji, zeskanuj kod QR na skrzynce lub wprowadź ręcznie identyfikator skrzynki.',
  noBuildingsVisible:
    'Na obecnym widoku mapy nie widać żadnych budynków. Spróbuj dostosować mapę, aby zobaczyć więcej budynków.',
  noComponentsFound: 'Nie znaleziono komponentów dla tego piętra',
  noFavorite: 'Nie jest ulubiony',
  noFavoritesChoosen:
    'Nie wybrałeś jeszcze żadnych ulubionych budynków. Przejdź do swojego budynku i kliknij ikonę gwiazdki, aby dodać go do ulubionych budynków.',
  noInstallationControlFound:
    'ESN elektroniki drzwiowej nie odpowiada ESN ostatniego biegu Kontroli Instalacji. Sprawdź, czy jesteś podłączony do właściwej windy.',
  noSubcontractors: 'Nie znaleziono podwykonawców.',
  noSubcontractorMessage:
    'Obecnie Twoja firma nie jest połączona z żadnym podwykonawcą. Aby to zrobić, wyślij zaproszenie.',
  noSubscription: 'Brak',
  note: 'Notatka',
  noQRCodeDetected:
    'Nie wykryto jeszcze kodu QR. Przesuń kamerę tak, aby kod QR był wyraźny i w pełni widoczny w polu widzenia.',
  nudgingSpeedDescription:
    'Prędkość drzwi w warunkach niskiej prędkości. Krzywa otwierania/zamykania jest obliczana, aby osiągnąć tę ustawioną prędkość, jeśli tryb jest aktywny.',
  nudgingSpeedNote:
    'Rzeczywista prędkość drzwi jest również związana z przełącznikami DIP prędkości napędu. Ustawienie na prędkość 4 oznacza 100% wartości 3.',
  nudgingSpeedTitle: 'Prędkość Przymilania',
  obsolete: 'Nieaktualne',
  offline: 'Offline',
  offlineConnection:
    'Jesteś obecnie offline, ale możesz używać Narzędzia Programowania.',
  ok: 'Regularna Konserwacja',
  online: 'Online',
  openHoldForceDescription: 'Siła stosowana w pozycji otwarcia.',
  openHoldForceNote:
    'Siła ta powinna wynosić od 50N do 60N. Zbyt niska siła może powodować niechciane zamknięcia i otwarcia. Zbyt wysoka siła końcowa otwarcia nagrzewa silnik niepotrzebnie i może prowadzić do pracy w trybie redukcji.',
  openHoldForceTitle: 'Siła Utrzymania Otwarcia',
  openSpeedDescription:
    'Maksymalna prędkość drzwi w kierunku otwierania. Krzywa otwierania jest obliczana, aby osiągnąć tę ustawioną prędkość, jeśli to możliwe.',
  openSpeedNote:
    'Rzeczywista prędkość drzwi jest również związana z przełącznikami DIP prędkości napędu. Ustawienie na prędkość 4 oznacza 100% wartości 1.',
  openSpeedTitle: 'Prędkość Otwierania',
  otherReason: 'Inny powód',
  overdue: 'Przeterminowane',
  parameter: 'Parametr',
  partInformation: 'Informacje o Części',
  partIsStillOk: 'Część jest nadal OK',
  partNumber: 'Numer Części',
  partName: 'Nazwa Części',
  password: 'Hasło',
  passwordResetSent:
    'Wysłaliśmy Ci e-mail z instrukcjami dotyczącymi resetowania hasła. Sprawdź swoją skrzynkę odbiorczą.',
  pendingInvites: 'Oczekujące Zaproszenia',
  pendingUserInvites: 'Oczekujące Zaproszenia Użytkowników',
  pendingSubcontractorInvites: 'Oczekujące Zaproszenia Podwykonawców',
  planningAndAlerts: 'Planowanie i alerty',
  position: 'Pozycja',
  positionOutDescription:
    'Wprowadzając ten parametr, przekaźnik pozycji (związany z FPC) może być dostosowany do przełączania się w dowolnej pozycji drzwi. Standardowe ustawienie to 0 (nieaktywne).',
  positionOutNote:
    'W przypadku ustawienia tego parametru na inną wartość, przekaźnik przełącza się po osiągnięciu pozycji. Funkcja ta jest używana głównie w szybach z kabinami przedsuwnymi.',
  positionOutTitle: 'Pozycja Wysunięcia',
  postpone: 'Przełóż',
  powerReductionFactor: 'Współczynnik Redukcji Mocy',
  prev: 'Poprzedni',
  preventiveMaintenance: 'Konserwacja Zapobiegawcza',
  preventiveMaintenanceDescription:
    'Wymień części zamienne drzwi windy zanim się zepsują.',
  preventiveMaintenanceUnavailable:
    'Wprowadzone dane są kompletne! ElevatorSense teraz obliczy harmonogram konserwacji w oparciu o użytkowanie Twojej windy. Proces ten może potrwać kilka minut.',
  priority: 'Priorytet',
  product: 'Produkt',
  programmingDisabledHint:
    'Programowanie elektroniki jest obecnie wyłączone przez sprzęt. Upewnij się, że tryb serwisowy napędu jest włączony, aby umożliwić programowanie urządzenia.',
  programmingErrorConnection: (
    <>
      <p>Napotkaliśmy problemy z połączeniem z skrzynką ELEVATORSENSE.</p>
      <p>
        Upewnij się, że wyłączono "połączenie danych mobilnych" i upewnij się,
        że jesteś połączony tylko z siecią Wi-Fi skrzynki ELEVATORSENSE.
      </p>
      <p>
        Następnie naciśnij przycisk <b>kontynuuj</b> poniżej.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    'Nie udało się otworzyć okna. Upewnij się, że wyskakujące okna nie są blokowane przez przeglądarkę.',
  programmingErrorRequest:
    'Żądanie nie powiodło się. Upewnij się, że Twoje urządzenie jest podłączone do sieci Wi-Fi skrzynki ELEVATORSENSE.',
  programmingTool: 'Programowanie Bezprzewodowe',
  programmingToolDescription: 'Łatwa konfiguracja elektroniki drzwiowej.',
  pulley_is_touching_belt: 'Koło Pasowe',
  pulley_is_touching_belt_actions:
    'Dostosuj ustawienie pasa tak, aby pas nie dotykał krawędzi koła pasowego.',
  pulley_is_touching_belt_description: 'Koło pasowe dotyka paska.',
  readyToRunTest: 'Gotowy do przeprowadzenia testu.',
  realtimeMonitoringDescription:
    'Zobacz dane z czujników i wykresy ruchu drzwi w czasie rzeczywistym.',
  realtimeMonitoringTitle: 'Diagnostyka w Czasie Rzeczywistym',
  recommended: 'Zaplanuj Sprawdzenie Wymiany',
  recommendedActions: 'Zalecane Działania',
  recommendedDateOfExchange: 'Zalecana Data Sprawdzenia Wymiany',
  reload: 'Przeładuj',
  remove: 'Usuń',
  reopenTimeDescription:
    'Parametr Ponownego Otwarcia definiuje czas oczekiwania po automatycznym ruchu ponownego otwarcia w pozycji końcowej otwarcia przed ponownym zamknięciem drzwi.',
  reopenTimeNote:
    'Funkcja ta jest używana w przypadku procedury ponownego otwierania wyzwalanej przez elektronikę drzwiową, a nie przez główny sterownik windy.',
  reopenTimeParameter: 'Parametr Czasu Ponownego Otwarcia',
  reopenTimeTitle: 'Czas Ponownego Otwarcia',
  repeatFloor: 'Proszę powtórzyć proces kontroli instalacji dla tego piętra!',
  repeatWholeRun: (
    <>
      Proszę powtórzyć <b>cały</b> proces kontroli instalacji!
    </>
  ),
  replaced: 'Zastąpiono',
  replacementWasPostponed: 'Wymiana została przełożona na:',
  requestNewLink: 'Poproś o nowy link',
  resetPassword: 'Zresetuj hasło',
  resetPasswordText: 'Wprowadź nowe hasło.',
  revoke: 'Cofnij',
  right: 'Prawo',
  rollerBumper: 'Rolki Zderzaka',
  rollerSynchronisationRope: 'Rolki Sznura Synchronizacyjnego',
  runs: 'Biegi',
  save: 'Zapisz',
  scan: 'Skanuj',
  scanned: 'Zeskanowano',
  scanAgain: 'Skanuj ponownie',
  searchPlaces: 'Wyszukaj miejsca',
  select: 'Wybierz',
  selectCameraMode: 'Wybierz tryb kamery',
  selectDoorModel: 'Wybierz Model Drzwi',
  selectDoorType: 'Wybierz Typ Drzwi',
  selectModifiedFloors:
    'Wybierz piętra, na których dokonano zmian od ostatniego biegu testowego. Piętra, na których wystąpiły błędy podczas ostatniego biegu, są zaznaczone wstępnie.',
  selectPartsHint:
    'Sprawdź, który element jest zainstalowany i kliknij na odpowiedni obraz.',
  send: 'Wyślij',
  sendResults: 'Wyślij Wyniki',
  sendTo: 'Wyślij wyniki na następujące adresy e-mail:',
  sensorData: 'Dane Czujników',
  serviceDriveSwitch: 'Przełącznik Napędu Serwisowego',
  settings: 'Ustawienia',
  share: 'Udostępnij',
  shareBody: (link: string) => `Zobacz wynik testu:\n${link}`,
  shareResults: 'Udostępnij Wyniki',
  shareSubject: 'Wynik ELEVATORSENSE',
  shoe: 'But',
  showPassedTests: 'Pokaż zdane testy ...',
  showTerms: 'Pokaż warunki użytkowania',
  signUp: 'Zarejestruj się',
  skid: 'Poślizg',
  slideingshoe_failure: 'Awaria Ślizgacza',
  slideingshoes_usage: 'Użycie Ślizgaczy',
  somethingWentWrong: 'Coś poszło nie tak. Spróbuj ponownie.',
  sortBy: 'Sortuj według',
  sparePartDoesNotFit: 'Część zamienna nie pasuje',
  sparePartNotAvailable: 'Część zamienna niedostępna',
  speed: 'Prędkość',
  start: 'Rozpocznij',
  startNewTestRun: 'Nowy Bieg Testowy',
  started: 'Uruchomiono',
  starting_floor: 'Piętro Początkowe',
  status: 'Status',
  subcontractorNotFound: 'Nie znaleziono podwykonawcy',
  subcontractors: 'Podwykonawcy',
  subcontractorManagement: 'Zarządzanie Podwykonawcami',
  subscriptionHint:
    'Otrzymasz powiadomienia e-mail tylko wtedy, gdy zakupisz odpowiednie produkty dla swojej Skrzynki ElevatorSense.',
  subscriptionText: 'Wybierz powiadomienia e-mail, które chcesz otrzymywać.',
  subscriptions: 'Powiadomienia',
  summarySubscription: 'Raport Dzienny',
  summarySubscriptionExplanation: (
    <>
      <b>Raport dzienny</b> zawiera e-mail z podsumowaniem wszystkich wybranych
      budynków i wind z oznaczeniem liczby błędów.
    </>
  ),
  temperature: 'Temperatura',
  testCompleted: 'Test zakończony.',
  testResults: 'Wyniki Testu',
  time: 'Czas',
  tooHigh: 'Zbyt wysokie',
  tooLoose: 'Zbyt luźne',
  tooLow: 'Zbyt niskie',
  tooTight: 'Zbyt napięte',
  top: 'Góra',
  topToBottom: 'Od Góry do Dołu',
  touching: 'dotyka',
  transformer: 'Transformator',
  truckRoller: 'Rolki Ciężarówki',
  truckRollerInferior: 'Dolne Rolki Ciężarówki',
  truckRollerSuperior: 'Górne Rolki Ciężarówki',
  twisted_cd_panels: 'Wykrzywione Panele CD',
  twisted_ld_panels: 'Wykrzywione Panele',
  unlinkBox: 'Odłącz Skrzynkę',
  unreadNotifications: (n: number) =>
    `${n} nieprzeczytane powiadomienie${n === 1 ? '' : 'a'}`,
  unshare: 'Przestań Udostępniać',
  update: 'Aktualizuj',
  updateRequired: 'Wymagana Aktualizacja',
  updateStaticData: 'Aktualizuj Dane',
  updating: 'Aktualizacja...',
  upToDate: 'Aktualne',
  usage: 'Użycie',
  usedElectronics: 'Użyte Windy',
  validFrom: 'Ważne od',
  validUntil: 'Ważne do',
  vibration: 'Wibracje',
  viewErrorLog: 'Wyświetl Dziennik Błędów',
  waitWhileInitializingTest: 'Poczekaj na inicjalizację testu...',
  waitingForData: 'Oczekiwanie na nowe dane',
  waitingForTestResults: 'Oczekiwanie na wyniki testu...',
  warning: 'Ostrzeżenie',
  warnings: 'Ostrzeżenia',
  warrantyExtensionRequestedOn: (date: string) =>
    `Przedłużenie gwarancji zostało zażądane ${date}`,
  whyNotReplaced: 'Dlaczego komponent nie został wymieniony?',
  wifiScanned: 'Wi-Fi zostało zeskanowane',
  years: 'lata',
  yes: 'Tak',
  zero_position: 'Pozycja Zero',
  zero_position_description: 'Pozycja zero jest nieprawidłowa.',
  zero_position_eco_actions:
    'Procedura ECO+:\n  1) Ustaw S1DIP1 na ON (Tryb Serwisowy).\n  2) Zamknij drzwi ręcznie (pozycja zero).\n  3) Naciśnij przycisk uczenia > 10s (Długie Uczenie).\n  4) Otwórz i zamknij drzwi za pomocą przycisku napędu serwisowego.\n  5) Przytrzymaj przyciski na końcu otwarcia drzwi i końcu zamknięcia drzwi przez > 2s.\n  Wynik: Dioda LED powinna zgasnąć po 2 cyklach.\n  ',
  zero_position_midi_actions:
    'Procedura MIDI/SUPRAV2:\n  Zamknij drzwi ręcznie (pozycja zero).\n  Postępuj zgodnie z instrukcjami na piktogramie.',
  yourBuilding: 'Twój Budynek',
  yourBuildings: 'Twoje Budynki',
};
