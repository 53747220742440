import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useI18n } from '../../I18nProvider';
import { useOffline } from '../../OfflineProvider';
import { color, fontSize, fontWeight, space } from '../../tokens';
import { useUserState } from '../../UserProvider';
import { logout } from '../../utils/api';
import { useVersion } from '../../VersionProvider';
import {
  AdminIcon,
  BellIcon,
  DocumentationIcon,
  ExternalLinkIcon,
  HomeIcon,
  IntercomIcon,
  LogoutIcon,
  MembersIcon,
  RemoteIcon,
  UpdateIcon,
  UserIcon,
} from './Icons';
import LanguageDropdown from './LanguageDropdown';
import { Row } from './Row';
import { Stack } from './Stack';
import { SectionHeading, TextXs } from './typography';

const DOCUMENTATION_LINK_EN = import.meta.env.VITE_DOCUMENTATION_LINK_EN;
const DOCUMENTATION_LINK_DE = import.meta.env.VITE_DOCUMENTATION_LINK_DE;
const DOCUMENTATION_LINK_FR = import.meta.env.VITE_DOCUMENTATION_LINK_FR;
const DOCUMENTATION_LINK_ES = import.meta.env.VITE_DOCUMENTATION_LINK_ES;
const DOCUMENTATION_LINK_IT = import.meta.env.VITE_DOCUMENTATION_LINK_IT;

const Shade = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  display: none;
  opacity: 0;
  .visible & {
    display: block;
    opacity: 0.6;
  }
`;

const Panel = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  width: 30ch;
  max-width: 80vw;
  background: ${color.primary};
  color: ${color.textOnPrimary};
  display: flex;
  flex-direction: column;
  padding: ${space[5]};
  transition: all 300ms;
  box-shadow: 0 0 8px #0005;
  transform: translateX(-110%);
  .visible > & {
    transform: translateX(0);
  }
`;

const Burger = styled.button`
  position: fixed;
  top: ${space[4]};
  left: 0;
  color: inherit;
  z-index: 1;
  .visible > & {
    z-index: 3;
  }
  padding: 0;
  height: ${space[11]};
  width: ${space[11]};
  border: none;
  background: transparent;
  ::before,
  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    background: currentColor;
    left: 12px;
    width: 30px;
    height: 1px;
    border: 1px solid currentColor;
    border-radius: 6px;
    transform-origin: center center;
    transition: all 300ms;
  }
  ::before {
    transform: translateY(-4px);
    .visible > & {
      transform: rotate(-45deg);
    }
  }
  ::after {
    transform: translateY(4px);
    max-width: 20px;
    .visible > & {
      max-width: none;
      transform: rotate(45deg);
    }
  }
`;

const Menu = styled(Stack)`
  margin-top: 4rem;
  margin-bottom: auto;
  align-self: center;
`;

const MenuItem = styled(Row)<{ disabled?: boolean }>`
  display: flex;
  padding: 0;
  text-decoration: none;
  border: none;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`;

const Version = styled.div`
  align-self: center;
  font-size: ${fontSize.xs};
  font-weight: ${fontWeight.medium};
  -webkit-font-smoothing: auto;
  color: rgba(255, 255, 255, 0.2);
  margin-top: 0 !important;
`;

const LanguageContainer = styled.div`
  position: absolute;
  right: 0;
  padding-right: 20px;
`;

export function SideBar() {
  const offline = useOffline();
  const version = useVersion();

  const { user, setUser } = useUserState();
  const { translations: t } = useI18n();

  const [visible, setVisible] = useState(false);

  const [documentationLink, setDocumentationLink] = useState(
    DOCUMENTATION_LINK_EN
  );

  useEffect(() => {
    if (user) {
      setDocumentationLink(getDocumentationLink(user.locale));
    }
  }, [user?.locale]);

  const getDocumentationLink = (locale: string) => {
    switch (locale) {
      case 'de':
        return DOCUMENTATION_LINK_DE;
      case 'fr':
        return DOCUMENTATION_LINK_FR;
      case 'es':
        return DOCUMENTATION_LINK_ES;
      case 'it':
        return DOCUMENTATION_LINK_IT;
      default:
        return DOCUMENTATION_LINK_EN;
    }
  };

  if (!user) return null;

  return (
    <div className={visible ? 'visible' : ''}>
      <Shade onClick={() => setVisible(false)} />
      <Burger onClick={() => setVisible((v) => !v)} />
      <Panel>
        <Menu>
          <MenuItem
            as={Link}
            to="/"
            disabled={offline}
            onClick={() => setVisible(false)}
          >
            <HomeIcon />
            <span>{t.home}</span>
          </MenuItem>
          <MenuItem
            as={Link}
            to="/programming/parameters"
            onClick={() => setVisible(false)}
          >
            <RemoteIcon />
            <span>{t.programmingTool}</span>
          </MenuItem>
          <MenuItem
            as={Link}
            to="/subscriptions"
            disabled={offline}
            onClick={() => setVisible(false)}
          >
            <BellIcon />
            <span>{t.subscriptions}</span>
          </MenuItem>
          {user?.role === 'admin' && (
            <MenuItem
              as={Link}
              to="/admin"
              disabled={offline}
              onClick={() => setVisible(false)}
            >
              <AdminIcon />
              <span>{t.admin}</span>
            </MenuItem>
          )}
          {(user?.role === 'manager' || user?.role === 'admin') && (
            <>
              <MenuItem
                as={Link}
                to="/members"
                disabled={offline}
                onClick={() => setVisible(false)}
              >
                <MembersIcon />
                <span>{t.members}</span>
              </MenuItem>
              {/* <MenuItem
                as={Link}
                to="/subcontractors"
                disabled={offline}
                onClick={() => setVisible(false)}
              >
                <GearIcon />
                <span>{t.subcontractors}</span>
              </MenuItem> */}
            </>
          )}
          <MenuItem
            as={Link}
            to="/box-software"
            disabled={offline}
            onClick={() => setVisible(false)}
          >
            <UpdateIcon />
            <span>{t.boxSoftwareTitle}</span>
          </MenuItem>
          <MenuItem
            as="a"
            href={documentationLink}
            disabled={offline}
            onClick={() => setVisible(false)}
            target="_blank"
          >
            <DocumentationIcon />
            <span>{t.documentation}</span>
            <ExternalLinkIcon />
          </MenuItem>
          <MenuItem
            as="button"
            disabled={offline}
            onClick={async () => {
              await logout();
              setUser(null);
            }}
          >
            <LogoutIcon />
            <span>{t.logout}</span>
          </MenuItem>
        </Menu>
        <Stack gap="40px">
          <Stack>
            <IntercomIcon
              size="3em"
              style={{ alignSelf: 'center', color: 'rgba(255,255,255,0.2)' }}
            />
            <Version>{version}</Version>
          </Stack>
          <Row>
            <UserIcon size="36px" />
            <div>
              <TextXs>{user?.email}</TextXs>
              <SectionHeading>{user?.company.name}</SectionHeading>
            </div>
            <LanguageContainer>
              <LanguageDropdown />
            </LanguageContainer>
          </Row>
        </Stack>
      </Panel>
    </div>
  );
}
