import React from 'react';

import { Translations } from './en';

export const it: Translations = {
  aborted: 'Interrotto',
  accelerationDescription:
    'Il fattore di accelerazione che viene utilizzato per il calcolo della curva.',
  accelerationNote:
    'Questo fattore deve essere sufficientemente alto per raggiungere le velocità impostate in caso contrario la porta non raggiungerà la velocità richiesta.',
  accelerationTitle: 'Accelerazione',
  acceptTerms: 'Accetto le condizioni di utilizzo',
  add: 'Aggiungi',
  addBuilding: 'Aggiungi edificio',
  addLift: 'Aggiungi ascensore',
  addressHint: "Inserire il nome dell'edificio/azienda o l'indirizzo.",
  adjustCameraSettings:
    'È inoltre possibile regolare le impostazioni della fotocamera dal menu a tendina.',
  admin: 'Amministratore',
  alreadyHasSubcontractorsError:
    'In qualità di subappaltatore, non puoi avere subappaltatori. Si prega di rimuovere i subappaltatori esistenti prima di accettare questa invito.',
  all: 'Tutti',
  allBuildings: 'Tutti gli edifici',
  allBuildingsSubscription: 'Da tutti gli edifici',
  alphabetical: 'Alfabetico',
  ambientTemperature: 'Temperatura box',
  assetNumber: 'ID ascensore',
  assetNumberHint:
    "Inserire l'ID dell'ascensore (codice impianto), se disponibile.",
  auto: 'Automatico',
  back: 'Indietro',
  backToDashboard: 'Torna alla dashboard',
  belt_tension: 'Tensione della cinghia',
  belt_tension_actions:
    "Regolare la tensione della cinghia come descritto nelle istruzioni per l'installazione.",
  beltclamp: 'Morsetto connessione cinghia-abbinamento',
  belt_tension_description: 'La tensione della cinghia è troppo alta/bassa.',
  bottom: 'in basso',
  bottomToTop: "Dal basso verso l'alto",
  boxLinkedToOtherLift: (lift: string, building: string) =>
    `Il box è collegato a ${lift} in ${building}. Continuare?`,
  boxNotReady: 'Il box selezionato non è pronto.',
  boxOfflineHint:
    'Se si desidera aggiornare uno dei box elencati di seguito collegarlo prima a Internet.',
  boxScannedNoIdFound: 'Scatola scansionata, ma nessun ID trovato.',
  boxSoftware: 'Software',
  boxSoftwareStatusError:
    'Impossibile recuperare lo stato delle scatole: controlla la connessione internet della scatola e riprova. Se il problema persiste, contatta il tuo rappresentante Wittur.',
  boxSoftwareTitle: 'Software della Scatola',
  boxSoftwareUpdateError:
    'Aggiornamento del software della scatola non riuscito: controlla la connessione internet della scatola e riprova. Se il problema persiste, contatta il tuo rappresentante Wittur.',
  box_id: 'ID box',
  brakeParDescription:
    'La regolazione di questo parametro influisce sul ritardo e sullo scorrimento in fine corsa di apertura/chiusura (regolazione preimpostata a "5")..',
  brakeParNote:
    "Le regolazioni possibili sono ''0''..''9'' e la frenata più rapida si ottiene con il parametro ''0'' mentre il ritardo massimo si ottiene con il parametro \"9\".",
  brakeParTitle: 'Fine corsa meccanico serratura',
  buffer: 'Fine corsa meccanico serratura',
  building: 'Edificio',
  buildingHasNoElevator: 'Questo edificio non ha ascensori.',
  buildingName: 'Nome edificio',
  buildings: 'Edifici',
  buildingsWithoutValidAddress: 'Edifici senza indirizzo valido',
  bushingClose: 'Pulsante di chiusura',
  bushingOpen: 'Pulsante di apertura',
  cablechain: 'Catena guidacavi',
  cableTensorSpring: 'Molla tensione fune accoppiamento carrelli',
  callFloorsInstruction: (
    <>
      Apri la porta e <b>chiama tutti gli altri piani</b>. Esci dalla cabina e
      fai clic su "Continua"
    </>
  ),
  cameraMode: 'Modalità fotocamera',
  cancel: 'Annulla',
  car: 'Cabina',
  carDoor: 'Porta di cabina',
  carDoorCounter:
    'Contatore dei cicli porta della precedente scheda elettronica',
  carDoorModified: 'La porta di cabina è stata modificata',
  cd_counter_rollers_installation: 'Rotelle di contrasto',
  cd_counter_rollers_installation_actions:
    "Per ogni porta di piano, controllare che le rotelle possano essere ruotate facilmente a mano e che la loro distanza dal binario non sia eccessiva. In caso contrario, allentare le rotelle e regolarle di nuovo in modo che siano il più possibile vicine al binario, ma senza contatto, e possano essere ruotate facilmente a mano. Se è necessario smontare l'abbinamento per accedere alle rotelle, seguire le istruzioni per il montaggio.",
  cd_counter_rollers_installation_description:
    'Le rotelle di contrasto della porta di cabina non sono installate correttamente.',
  cd_roller: 'Rotelle porta di cabina',
  chooseFloorOrCardoor: 'Scegli porta di piano / di cabina',
  choosePartNumber: 'Scegli il codice articolo',
  chooseReason: 'Scegli un motivo',
  chooseStartingFloorInstructions:
    'Selezionare il piano di avvio della corsa di controllo. Il piano di avvio può essere il più basso o il più alto.',
  clearDriveErrors: 'Cancella',
  close: 'Chiudi',
  closeDoorInstructions: (floor: string) => (
    <>
      Verso il piano <b>{floor == 'più basso' ? 'più basso' : 'più alto'}</b> .
      Assicurati che la porta della cabina sia chiusa prima di procedere
    </>
  ),
  closeForcePotiDescription:
    "Impostazione del limite di forza utilizzato per l'apertura e la chiusura della porta.",
  closeForcePotiNote:
    "Regolabile tramite potenziometro sull'elettronica. Nelle elettroniche più recenti, come il MIDIV2, se necessario è possibile impostarlo in questa modalità (non è necessario regolarlo nelle applicazioni standard grazie all'autoapprendimento).",
  closeForcePotiTitle: 'Limite della forza di chiusura',
  closeHoldForceDescription: 'Forza che viene applicata a fine chiusura.',
  closeHoldForceNote:
    "Tale forza dovrebbe essere compresa tra 50 N e 70 N. Una forza troppo bassa causerà aperture indesiderate dell'abbinamento. Una forza troppo alta riscalderà inutilmente il motore, che potrebbe funzionare in modalità ridotta.",
  closeHoldForceTitle: 'Forza (mantenuta) di fine chiusura',
  closeSpeedDescription:
    'Velocità massima della porta in direzione di chiusura. La curva di chiusura viene calcolata in modo da raggiungere la velocità selezionata, se possibile.',
  closeSpeedNote:
    'La velocità reale della porta dipende anche dagli interruttori DIP del controller della porta per la regolazione della velocità.  Regolazione alla velocità 4 significa il 100% del Valore 2.',
  closeSpeedTitle: 'Velocità di chiusura',
  closing_device_failure: 'Dispositivo di chiusura',
  closing_device_failure_actions:
    'Controllare che il dispositivo di chiusura per la porta di piano funzioni correttamente in tutti i suoi componenti (Spirator, molla di chiusura e contrappeso). La porta di piano deve potersi chiudere automaticamente da ogni posizione.',
  closing_device_failure_description:
    'Il dispositivo di chiusura non è installato correttamente. La forza necessaria per chiudere la porta è eccessiva.',
  companyName: "Nome dell'azienda",
  completed: 'Completato',
  componentDetails: 'Dettagli sui componenti',
  componentDetailsHint:
    "Per alcuni componenti esistono più varianti possibili. Si tratta di informazioni importanti per l'utilizzo della funzione di manutenzione preventiva. Fare clic sugli elementi elencati per selezionare la parte effettivamente installata.",
  confirm: 'Confermare',
  confirmDeleteBuilding: "Eliminare l'edificio?",
  confirmDeleteErrorHistory:
    'Vuoi davvero cancellare la cronologia degli errori?',
  confirmDeleteFavoriteBuilding: "Rimuovere l'edificio dai preferiti?",
  confirmDeleteLift: "Eliminare l'ascensore?",
  confirmInvite: 'Conferma e Collega',
  connectionErrorMessage:
    'Impossibile recuperare i dati a causa di un problema di connessione. Si prega di riprovare più tardi.',
  continue: 'Continua',
  contractorBuilding: "Edificio dell'appaltatore",
  contractorBuildings: "Edifici dell'appaltatore",
  contractors: 'Appaltatori',
  couplerelease: 'Sblocco abbinamento',
  couplerFriction: "Attrito dell'abbinamento",
  couplerMovementParameter: 'Parametro movimento abbinamento',
  couplerSpeedDescription:
    "Questo valore definisce la velocità massima della cinghia nell'area specifica dell'abbinamento.",
  couplerSpeedTitle: 'Velocità abbinamento',
  couplerWitdhDescription:
    "Questo valore definisce la distanza della cinghia nell'area dell'abbinamento. Questo valore risulta definita dopo la procedura di apprendimento.",
  couplerWitdhTitle: 'Larghezza abbinamento',
  currentVersion: 'Versione corrente',
  couplerwidth: 'Larghezza abbinamento',
  date: 'Data',
  delete: 'Elimina',
  deleteErrorHistory: 'Cancellare cronologia errori',
  directConnection: (
    <>
      <p>
        Connettiti al Wi-Fi del box ELEVATORSENSE eseguendo la scansione del
        codice QR stampato sul box con la fotocamera del tuo smartphone.
      </p>
      <p>
        Una volta connesso, torna a questa schermata e premi il pulsante{' '}
        <b>Continua</b> qui sotto.
      </p>
    </>
  ),
  disconnectProgrammingTool: 'Disconnetti Wireless Programming',
  divpulley: 'Puleggia di rinvio',
  documentation: 'Documentazione',
  doorContact: 'Contatto porta',
  doorCycleCounter: 'Contatore cicli porta',
  doorCycleError1: 'Nessun dato ricevuto sul ciclo della porta.',
  doorCycleError2: 'Il movimento del ciclo della porta è stato interrotto.',
  doorCycleError4: 'È stata rilevata una riapertura della porta.',
  doorCycleError5: 'È stata rilevata una richiusura della porta.',
  doorCycleError7: 'Nessun ciclo della porta valido ricevuto.',
  doorCycles: 'Cicli della porta',
  doorInstallationDate: 'Data di installazione della porta',
  doorFriction: 'Attrito della porta',
  doorIsClosed: 'Sì, la porta è chiusa',
  doorModel: 'Modello porta',
  doorModelNotSupported:
    'Il modello di porta selezionato non è disponibile per la manutenzione preventiva.',
  doorMovementParameter: 'Parametro movimento della porta',
  doorPosition: 'Posizione porta',
  doorSerialNumber: 'Numero di serie della porta',
  doorType: 'Tipo di porta',
  doorwidth: 'Larghezza porta',
  downloadCertificate: 'Scarica il certificato',
  downloadRequestedExtendedWarranty:
    'Scarica tutte le richieste di estensione della garanzia',
  downloadLicense: 'Scarica tutte le licenze come CSV',
  drive_motor_noise: 'Rumore del motore di azionamento',
  driveesn: 'ESN',
  drivesoftware: 'Firmware',
  editBuilding: 'Modifica edificio',
  editLift: 'Modifica ascensore',
  elecBoard: 'Scheda elettronica',
  elevatorIdNotFound: (
    <>
      Per richiedere l'estensione della garanzia, è necessario inserire il
      numero di serie della porta nelle impostazioni dell'ascensore.
      impostazioni dell'ascensore.
      <b>Cliccare qui per accedere alle impostazioni.</b>
    </>
  ),
  elevatorInstallationDate: "Data di installazione dell'ascensore",
  elevatorUsageType: "Tipo di utilizzo dell'ascensore",
  email: 'E-mail',
  encoderTemperature: 'Temperatura encoder',
  error: 'Errore',
  errorDescription: "Descrizione dell'errore",
  errorEarlierThanInstallationDate:
    'Non è possibile inserire una data precedente alla data di installazione.',
  errorInvalid: 'Inserire un valore valido.',
  errorInvalidOperation: 'Operazione non valida',
  errorMessages: 'Messaggi di errore',
  errorMessagesDescription: 'Display dei messaggi di errore registrati.',
  errorNotFound: 'Risorsa non trovata',
  errorRequired: 'Questo campo è obbligatorio.',
  errorRetryCamera:
    'Impossibile accedere alla fotocamera. Riprovo, la pagina verrà ricaricata...',
  errorUnknownOrForbiddenBox:
    'Il box non è disponibile. Contattare il servizio di assistenza Wittur per richiedere supporto.',
  errorUnableToAccessCamera:
    'Impossibile accedere alla fotocamera dopo diversi tentativi. Si prega di aggiornare la pagina o controllare i permessi della fotocamera.',
  errors: 'Errori',
  errorsEmpty: 'Nessun messaggio.',
  errorsEmptyHint: 'Verifica i filtri e, se necessario, modificali.',
  extendWarranty: 'Estensione della garanzia',
  fatalError: 'Errore irreversibile',
  fatalErrors: 'Errori irreversibili',
  favorite: 'Preferito',
  favorites: 'Preferiti',
  figureAdjustment: 'Regolazione',
  figureAugustaEVO: 'Esempio Augusta EVO',
  figureClosingWeight: 'Peso di chiusura',
  figureEco: 'ECO+',
  figureHydra: 'Esempio Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'Non OK',
  figureOk: 'OK',
  figureSGV: 'Esempio SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Molla di chiusura',
  firstName: 'Nome',
  floor: 'Piano',
  floors: 'Piani',
  followingBoxScanned: 'La scatola seguente è stata scansionata',
  forgotPassword: 'Hai dimenticato la password?',
  forgotPasswordText:
    'Inserisci il tuo indirizzo e-mail per reimpostare la password.',
  groundFloor: 'Piano terra',
  home: 'Pagina iniziale',
  incompleteElevatorSettings:
    "Per utilizzare Preventive Maintenance abbiamo bisogno di maggiori informazioni su questo ascensore. Vai alle impostazioni dell'ascensore e inserisci le informazioni mancanti.",
  initialized: 'Inizializzato',
  installationControl: 'Installation Control',
  installationControlDescription:
    'Esegui controlli automatici per rilevare problemi di installazione comuni.',
  installationControlExecutedBy: 'Eseguito da',
  installationControlHeadline: "Ultimo stato dell'installazione",
  installationControlHistory: 'Cronologia corse',
  installationControlHistoryEmpty: 'Nessuna corsa di installazione registrata',
  installationControlInstructions: 'Mostra istruzioni',
  installationControlSubheadline: 'Riepilogo di tutte le corse',
  installationDateErrorMessage:
    "Non è possibile inserire una data di installazione della porta precedente alla data di installazione dell'ascensore",
  instantSubscription: 'Notifica istantanea',
  instantSubscriptionExplanation: (
    <>
      {' '}
      La <b>notifica istantanea</b> è una notifica immediata inviata via e-mail
      non appena si verifica un errore irreversibile su un ascensore. Gli errori
      irreversibili sono errori che possono portare ad un malfunzionamento
      immediato dell'ascensore
    </>
  ),
  invitationExpired: "Il link dell'invito non è più valido.",
  inviteAsManager: "L'utente può invitare altri colleghi a registrarsi.",
  inviteCompanyWithSubcontractorsError:
    "L'azienda che stai cercando di invitare ha già dei subappaltatori e non può essere aggiunta come subappaltatore. Devono rimuovere i subappaltatori esistenti o scegliere un'altra azienda.",
  inviteCoworkers: 'Invita i colleghi',
  inviteSubcontractor: 'Invita un subappaltatore',
  inviteToSignUpMessage: (companyName: string) =>
    `L'azienda ${companyName} ti ha invitato a registrarti su ElevatorSense come loro subappaltatore. Si prega di compilare il modulo per registrarsi.`,
  inviteToBeSubcontractorMessage: (companyName: string) =>
    `${companyName} ti ha invitato a essere il loro subappaltatore su ElevatorSense.`,
  ipaddress: 'Indirizzo IP',
  joinText: (name: string) => (
    <>
      Iscriviti per unirti all'organizzazione <i>{name}</i>.
    </>
  ),
  landingDoor: 'Porta di piano - Piano',
  landing_lock_roller_adjustment:
    'Regolazione delle rotelle della serratura della porta di piano',
  landingCarDoorSpareParts: 'Ricambi porte di piano/cabina',
  lastError: 'Ultimo errore',
  lastExchangeDate: 'Ultima data di sostituzione',
  lastMaintenanceDate: "Data dell'ultima manutenzione",
  lastMaintenanceScope: 'Ultimo ambito di manutenzione',
  lastMaintenanceScopeHint:
    "Selezionare l'ambito di manutenzione raccomandato da Wittur che è stato effettivamente eseguito durante l'ultima visita di manutenzione.",
  lastName: 'Cognome',
  lastTimeDoorElectronicsChange:
    'Data di sostituzione della scheda elettronica della porta',
  latestSoftwarePackVersion: (version: string) =>
    `Versione del pacchetto software: ${version}`,
  ld_counter_rollers_installation: 'Rotelle di contrasto',
  ld_counter_rollers_installation_actions:
    "Per ogni porta di piano, controllare che le rotelle possano essere ruotate facilmente a mano e che la loro distanza dal binario non sia eccessiva. In caso contrario, allentare le rotelle e regolarle di nuovo in modo che siano il più possibile vicine al binario, ma senza contatto, e possano essere ruotate facilmente a mano. Se è necessario smontare l'abbinamento per accedere ai rulli, seguire le istruzioni per il montaggio.",
  ld_counter_rollers_installation_description:
    'Le rotelle di contrasto della porta di piano non sono installate correttamente.',
  ld_roller: 'Rotelle porta di piano',
  left: 'Sinistra',
  licenseRequired:
    'Non è stata acquistata una licenza valida per questo prodotto. Contatta il tuo referente commerciale Wittur per acquistare questo prodotto ElevatorSense.',
  lift: 'Ascensore',
  liftMovementDistance: "Movimento dell'ascensore",
  liftSetup: "Impostazione dell'ascensore",
  liftSetupComplete: 'Setup completato.',
  liftSetupError: 'I dati corrispondenti sono incompleti o mancanti.',
  liftSetupError40:
    "Il movimento dell'ascensore non corrisponde alla corsa di setup",
  liftSetupIncomplete: 'Setup incompleto.',
  liftSetupPending:
    "Eseguire il setup per la configurazione iniziale di ElevatorSense sull'ascensore. Senza questa operazione non sarà possibile utilizzare alcune funzionalità dei prodotti ElevatorSense o i risultati dei prodotti ElevatorSense potrebbero essere diversi. Fare clic qui per eseguire la configurazione.",
  liftSetupRepeat:
    'Corsa di setup non riuscita. Eseguire di nuovo la corsa seguendo il processo descritto.',
  lifts: 'Ascensori',
  linkBox: 'Collega il box',
  linkToWebshop: (link: string) => (
    <>
      Se hai una scatola ElevatorSense e desideri attivare una licenza, fai clic{' '}
      <a href={link} className="clickable-link">
        qui
      </a>
      .
    </>
  ),
  loading: 'Caricamento in corso',
  login: 'Accedi',
  loginText: "Esegui l'accesso con il tuo account.",
  logout: 'Logout',
  mandatory: 'Eseguire il controllo per la sostituzione',
  manageAccess: 'Gestire Accesso',
  manageElevators: 'Gestisci ascensori',
  manageSharedBuildingsAndElevator: 'Gestisci edifici/ascensori condivisi',
  manual: 'Manuale',
  maxElectronics: 'Ascensori max.',
  maxUsage: 'Utilizzo max.',
  members: 'Membri',
  misalignment_cd_vs_ld: 'Allineamento porta/abbinamento',
  misalignment_cd_vs_ld_actions:
    'Regolare le rotelle della serratura della porta di piano. La distanza tra le lame dello scivolo di abbinamento e le rotelle deve essere uguale a destra e a sinistra.',
  misalignment_cd_vs_ld_description:
    "L'abbinamento della porta di cabina non è allineato correttamente con le rotelle della serratura della porta di piano.",
  misalignment_cd_vs_ld_sill_gap: 'Distanza della soglia',
  misalignment_cd_vs_ld_sill_gap_actions:
    "La distanza della soglia a sinistra e a destra dell'apertura della porta non è uguale. Se ciò si verifica su tutti i piani, regolare la posizione della soglia della porta di cabina.",
  misalignment_cd_vs_ld_sill_gap_description:
    'La porta di piano non è parallela alla porta di cabina.',
  mode: 'Modalità',
  monitoring: 'Monitoraggio',
  motor: 'Motore',
  motorTemperature: 'Temperatura motore',
  myBuildingsSubscription: 'Solo dai miei preferiti',
  name: 'Nome',
  networkQuality: 'Qualità della rete',
  newInstallationControl: 'Nuovo controllo installazione',
  next: 'Succ',
  nextMaintenanceDate: 'Prossima data di manutenzione',
  no: 'No',
  notAuthorizedToConfirmInviteError:
    'Non sei autorizzato a confermare questo invito, poiché non è destinato al tuo utente connesso.',
  noBoxLinked:
    "L'ascensore non è ancora collegato ad un box ELEVATORSENSE. Per utilizzare tutte le funzionalità della app, eseguire la scansione del codice QR sul box oppure inserire manualmente l'id del box.",
  noBuildingsVisible:
    "Nessun edificio è visibile nell'attuale visualizzazione della mappa. Prova a regolare la mappa per vedere altri edifici.",
  noComponentsFound: 'Nessun componente trovato per questo piano',
  noFavorite: 'Non è un preferito',
  noFavoritesChoosen:
    "Non hai ancora aggiunto nessun edificio ai preferiti. Vai su un edificio e fai clic sull'icona della stella per aggiungerlo ai tuoi edifici preferiti.",
  noSubcontractors: 'Nessun subappaltatore trovato.',
  noInstallationControlFound:
    "L'ESN della scheda della porta non corrisponde all'ESN dell'ultima corsa di Installation Control. Per favore, verifica di essere connesso all'ascensore corretto.",
  noSubcontractorMessage:
    'Attualmente non ci sono subappaltatori collegati alla tua azienda. Per stabilirne uno, invia un invito.',
  noSubscription: 'Nessuno',
  note: 'Nota',
  noQRCodeDetected:
    'Il codice QR non è stato rilevato ancora. Per favore, sposta la fotocamera in modo che il codice QR sia a fuoco e completamente visibile nella visuale.',
  nudgingSpeedDescription:
    'Velocità della porta in condizione di bassa velocità forzata. La curva di apertura/chiusura viene calcolata in modo da raggiungere la velocità selezionata, se la bassa velocità forzata è attiva.',
  nudgingSpeedNote:
    'La velocità reale della porta dipende anche dagli interruttori DIP del controller della porta per la regolazione della velocità.  Regolazione alla velocità 4 significa il 100% del Valore 3.',
  nudgingSpeedTitle: 'Velocità di regolazione',
  obsolete: 'Obsoleto',
  offline: 'Offline',
  offlineConnection:
    'Al momento sei offline, ma puoi utilizzare la funzione Wireless Programming.',
  ok: 'Manutenzione regolare',
  online: 'Online',
  openHoldForceDescription: 'Forza che viene applicata a fine apertura.',
  openHoldForceNote:
    "Tale forza dovrebbe essere compresa tra 50 N e 60 N. Una forza troppo bassa causerà aperture e chiusure indesiderate. Una forza troppo alta all'estremità riscalderà inutilmente il motore, che potrebbe funzionare in modalità ridotta.",
  openHoldForceTitle: 'Forza (tenuta) estremità di apertura',
  openSpeedDescription:
    'Velocità massima della porta in direzione di apertura. La curva di apertura viene calcolata in modo da raggiungere la velocità selezionata, se possibile.',
  openSpeedNote:
    'La velocità reale della porta dipende anche dagli interruttori DIP del controller della porta per la regolazione della velocità.  Regolazione alla velocità 4 significa il 100% del Valore 1.',
  openSpeedTitle: 'Velocità di apertura',
  otherReason: 'Altro',
  overdue: 'In ritardo',
  parameter: 'Parametro',
  partInformation: 'Informazioni sul Componente',
  partIsStillOk: 'La parte è ancora OK',
  partNumber: 'Numero di Parte',
  partName: 'Nome della Parte',
  password: 'Password',
  passwordResetSent:
    "Ti abbiamo inviato un'e-mail con le istruzioni per reimpostare la password. Controlla la posta in arrivo.",
  pendingInvites: 'Inviti in sospeso',
  pendingUserInvites: 'Inviti utente in sospeso',
  pendingSubcontractorInvites: 'Inviti di subappaltatore in sospeso',
  planningAndAlerts: 'Pianificazione e avvisi',
  position: 'Posizione',
  positionOutDescription:
    'Immettendo questo parametro il relè di posizione (relativo a FPC) può essere regolato per commutarsi in qualsiasi posizione della porta. Il valore di regolazione predefinito è 0 (disattivata).',
  positionOutNote:
    'Nel caso in cui questo parametro venga impostato su un altro valore, il relè si attiva una volta raggiunta la posizione. Questa funzione viene utilizzata quasi esclusivamente nei vani con cabine in movimento anticipato.',
  positionOutTitle: 'Posizione esterna',
  postpone: 'Posticipa',
  powerReductionFactor: 'Fattore di riduzione della potenza',
  prev: 'Prec',
  preventiveMaintenance: 'Preventive Maintenance',
  preventiveMaintenanceDescription:
    "Sostituisci i pezzi di ricambio della porta dell'ascensore prima che creino problemi.",
  preventiveMaintenanceUnavailable:
    "I dati inseriti sono completi! ElevatorSense calcolerà ora il programma di manutenzione in base all'utilizzo dell'ascensore. Questo processo potrebbe richiedere alcuni minuti.",
  priority: 'Priorità',
  product: 'Prodotto',
  programmingDisabledHint:
    "La programmazione dell'elettronica è attualmente disabilitata dall'hardware. Assicurarsi che l'interruttore DIP 1 sia attivato per consentire la programmazione del dispositivo.",
  programmingErrorConnection: (
    <>
      <p>
        We are discovering troubles with the connection to ELEVATORSENSE box.
      </p>
      <p>
        Please make sure to switch off your "mobile data connection" and ensure
        you are connected to the WIFI of the ELEVATORSENSE box only.
      </p>
      <p>
        Afterwards hit the <b>continue</b> button below.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    'Impossibile aprire la finestra. Assicurarsi che i popup non siano bloccati dal browser.',
  programmingErrorRequest:
    'Richiesta non riuscita. Assicurarsi che il dispositivo sia connesso alla rete Wi-Fi del box ELEVATORSENSE.',
  programmingTool: 'Wireless Programming',
  programmingToolDescription: 'Configura facilmente i parametri della porta.',
  pulley_is_touching_belt: 'Puleggia',
  pulley_is_touching_belt_actions:
    "Regolare l'allineamento della cinghia in modo tale che non tocchi i bordi della flangia della puleggia.",
  pulley_is_touching_belt_description:
    'La flangia della puleggia è in contatto con la cinghia.',
  readyToRunTest: 'Pronto per eseguire il test.',
  realtimeMonitoringDescription:
    'Visualizza i dati raccolti dai sensori e i grafici di movimento delle porte in tempo reale.',
  realtimeMonitoringTitle: 'Real Time Diagnostics',
  recommended: 'Pianificare il controllo per la sostituzione',
  recommendedActions: 'Azioni suggerite',
  recommendedDateOfExchange: 'Data consigliata per la sostituzione',
  reload: 'Aggiorna',
  remove: 'Rimuovi',
  reopenTimeDescription:
    'Il parametro di riapertura definisce il periodo di attesa dopo il movimento di riapertura automatico in posizione di apertura, prima di chiudersi nuovamente.',
  reopenTimeNote:
    "Questa funzione viene utilizzata in caso di procedura di riapertura attivata dalla scheda elettronica della porta e non dal controller principale dell'ascensore.",
  reopenTimeParameter: 'Parametro tempo di riapertura',
  reopenTimeTitle: 'Tempo di riapertura',
  repeatFloor:
    "Ripeti la procedura di controllo dell'installazione per questo piano!",
  repeatWholeRun: (
    <>
      Ripetere <b>tutta</b> la procedura di controllo dell'installazione!
    </>
  ),
  replaced: 'Sostituisci ora',
  replacementWasPostponed: 'La sostituzione è stata posticipata in data:',
  requestNewLink: 'Richiedi un nuovo link',
  resetPassword: 'Reimposta la password',
  resetPasswordText: 'Inserisci una nuova password.',
  revoke: 'Revoca',
  right: 'Destra',
  rollerBumper: 'Rotella in gomma di battuta',
  rollerSynchronisationRope: 'Rotella per fune di sincronizzazione carrelli',
  runs: 'Corse',
  save: 'Salva',
  scan: 'Esegui la scansione',
  scanned: 'Scansionato',
  scanAgain: 'Scansionare di nuovo',
  searchPlaces: 'Ricerca luogo',
  select: 'Seleziona',
  selectCameraMode: 'Seleziona modalità fotocamera',
  selectDoorModel: 'Seleziona il modello di porta',
  selectDoorType: 'Seleziona Tipo di porta',
  selectModifiedFloors:
    "Seleziona i piani su cui sono state apportate modifiche dall'ultima corsa di prova. I piani su cui si sono verificati errori durante l'ultima corsa sono preselezionati.",
  selectPartsHint:
    "Verifica quale parte è installata e fare clic sull'immagine corrispondente.",
  send: 'Invia',
  sendResults: 'Invia risultati',
  sendTo: 'Invia i risultati ai seguenti indirizzi e-mail',
  sensorData: 'Dati del sensore',
  serviceDriveSwitch: 'Interruttore di azionamento modalità manutenzione',
  settings: 'Impostazioni',
  share: 'Condividi',
  shareBody: (link: string) => `Visualizza il risultato del test:\n${link}`,
  shareResults: 'Condividi i risultati',
  shareSubject: 'Risultati ELEVATORSENSE',
  shoe: 'Pattino di scorrimento anta',
  showPassedTests: 'Mostra i test superati ...',
  showTerms: 'Mostra le condizioni di utilizzo',
  signUp: 'Iscriviti',
  skid: 'Azionamento serratura di piano porte a battente',
  slideingshoe_failure: 'Malfunzionamento del pattino a scorrimento',
  slideingshoes_usage: 'Utilizzo dei pattini a scorrimento',
  somethingWentWrong: 'Qualcosa è andato storto. Riprova.',
  sortBy: 'Ordina per',
  sparePartDoesNotFit: 'Pezzo di ricambio non compatibile',
  sparePartNotAvailable: 'Pezzo di ricambio non disponibile',
  speed: 'Velocità',
  start: 'Avvia',
  startNewTestRun: 'Nuova corsa di prova',
  started: 'In esecuzione',
  starting_floor: 'Piano di avvio',
  status: 'Stato',
  subcontractorNotFound: 'Subappaltatore non trovato',
  subcontractors: 'Subappaltatori',
  subcontractorManagement: 'Gestione dei subappaltatori',
  subscriptionHint:
    'Riceverai le notifiche via e-mail solo se hai acquistato i prodotti appropriati per il box ElevatorSense.',
  subscriptionText: 'Seleziona quali notifiche desiderI ricevere via e-mail.',
  subscriptions: 'Notifiche',
  summarySubscription: 'Report giornaliero',
  summarySubscriptionExplanation: (
    <>
      Il <b>report giornaliero</b> include un'e-mail con una panoramica di tutti
      gli edifici e gli ascensori selezionati con l'indicazione del numero di
      errori
    </>
  ),
  temperature: 'Temperatura',
  testCompleted: 'Test completato.',
  testResults: 'Risultati del test',
  time: 'Ora',
  tooHigh: 'Troppo alto',
  tooLoose: 'Troppo allentato',
  tooLow: 'Troppo basso',
  tooTight: 'Troppo stretto',
  top: 'in alto',
  topToBottom: "Dall'alto al basso",
  touching: 'in contatto',
  transformer: 'Trasformatore',
  truckRoller: 'Rotella carrello',
  truckRollerInferior: 'Rotella inferiore carrello',
  truckRollerSuperior: 'Rotella superiore carrello',
  twisted_cd_panels: 'Pannelli porta di piano svergolati',
  twisted_ld_panels: 'Pannelli svergolati',
  unlinkBox: 'Scollega il box',
  unreadNotifications: (n: number) => `${n} notifiche non lette`,
  unshare: 'Smetti di condividere',
  update: 'Aggiornamento',
  updateRequired: 'Aggiornamento richiesto',
  updateStaticData: 'Aggiorna i dati',
  updating: 'Aggiornamento...',
  upToDate: 'Aggiornato',
  usage: 'Utilizzo',
  usedElectronics: 'Ascensori utilizzati',
  validFrom: 'Valido dal',
  validUntil: 'Valido fino al',
  vibration: 'Vibrazione',
  viewErrorLog: 'Visualizza registro errori',
  waitWhileInitializingTest: "Attendere l'inizializzazione del test ...",
  waitingForData: 'Nuovi dati in arrivo',
  waitingForTestResults: 'In attesa dei risultati del test ...',
  warning: 'Avviso',
  warnings: 'Avvisi',
  warrantyExtensionRequestedOn: (date: string) =>
    `L'estensione della garanzia è stata richiesta in ${date}`,
  whyNotReplaced: 'Perché il componente non è stato sostituito?',
  wifiScanned: 'Il WIFI è stato scansionato',
  years: 'anni',
  yes: 'Sì',
  zero_position: 'Posizione zero',
  zero_position_description: 'La posizione zero non è corretta.',
  zero_position_eco_actions:
    "Procedura ECO+:\n    1) Imposta S1DIP1 su ON (modalità di servizio).\n    2) Chiudi la porta manualmente (posizione zero).\n    3) Premi il pulsante di apprendimento > 10 s (Longlearn).\n    4) Apri e chiudi la porta utilizzando il pulsante di azionamento di servizio.\n    5) Tieni premuti i pulsanti all'estremità di apertura della porta e all'estremità di chiusura della porta per > 2 s.\n    Risultato: il LED di stato deve spegnersi dopo 2 cicli.\n    ",
  zero_position_midi_actions:
    'Procedura MIDI/SUPRAV2:\n    Chiudi la porta manualmente (posizione zero).\n    Segui le istruzioni del pittogramma.',
  yourBuilding: 'Il tuo edificio',
  yourBuildings: 'I tuoi edifici',
};
