import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../components/common/Button';
import { Stack } from '../../components/common/Stack';
import { useTranslation } from '../../I18nProvider';

type Props = {
  showButtons?: boolean;
};

export function ConnectionError({ showButtons = true }: Props) {
  const t = useTranslation();
  const reload = () => window.location.reload();

  return (
    <Stack>
      <div>{t.connectionErrorMessage}</div>
      {showButtons && (
        <Stack>
          <Button as={Link} variant="primary" to="../">
            {t.back}
          </Button>
          <Button variant="secondary" onClick={reload}>
            {t.reload}
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
