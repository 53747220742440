import React from 'react';
import styled from 'styled-components';

import { Stack } from '../../components/common/Stack';
import { DataGrid } from '../../components/DataGrid';
import { useDateFormat } from '../../hooks/useDateFormat';
import { useTranslation } from '../../I18nProvider';
import { InstallationRun } from '../../utils/appApi';
import { range } from '../../utils/results';
import { ResultPanel } from './ResultPanel';

const Name = styled.span`
  font-style: italic;
`;

type Props = {
  details: InstallationRun;
};
export function RunDetails({ details }: Props) {
  const { formatDate, formatTime } = useDateFormat();
  const t = useTranslation();

  const {
    run,
    lift,
    userFirstName,
    userLastName,
    floor_results,
    car_result,
    companyName,
  } = details;

  const renderName = () => (
    <Name>
      {userFirstName} {userLastName}
    </Name>
  );

  const getRunSummary = () => ({
    date: formatDate(details.date),
    time: formatTime(details.date),
    status: details.status,
    installationControlExecutedBy: renderName(),
    companyName: companyName,
    errors: details.failures || '0',
  });

  return (
    <Stack>
      <DataGrid data={getRunSummary()} />
      {range(lift.floors).map((i) => {
        const floorResult = floor_results?.find((f) => f.floor === i);

        return (
          <ResultPanel
            key={`${run}-${i}`}
            title={`${t.landingDoor} ${i}`}
            data={floorResult}
            loading={false}
            tipContext={lift.box?.static_data}
          />
        );
      })}
      <ResultPanel
        title={t.carDoor}
        data={car_result}
        loading={false}
        tipContext={lift.box?.static_data}
      />
    </Stack>
  );
}
