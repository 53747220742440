import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/common/Button';
import { ImageCard } from '../components/common/Card';
import { Hint } from '../components/common/Hint';
import { Loading } from '../components/common/Loading';
import { Page } from '../components/common/Page';
import { Stack } from '../components/common/Stack';
import { ToggleFilter } from '../components/common/ToggleFilter';
import { InviteUsers } from '../components/InviteUsers';
import { BuildingsMap } from '../components/map/BuildingsMap';
import { replaceMapKey } from '../components/map/placeUtils';
import { useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';
import { fontSize, fontWeight, space } from '../tokens';
import { useUser } from '../UserProvider';
import { Building } from '../utils/appApi';

export const Title = styled.div`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.base};
  line-height: 1;
  padding: ${space[2]} 0 ${space[2]} 0;
`;

const Dropdown = styled.select`
  margin-bottom: ${space[4]};
  padding: ${space[2]};
  font-size: ${fontSize.base};
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const renderBuildingCard = (
  building: any,
  allIsActive: boolean,
  t: any,
  replaceMapKey: (key: any) => string | undefined,
  isContractor: boolean,
  isSubcontractor: boolean
) => {
  const label = isContractor ? t.contractorBuilding : t.yourBuilding;
  return allIsActive || building.isFavorite ? (
    <ImageCard
      key={building.id}
      navigateTo={`/building/${building.id}/`}
      title={building.name || `${t.building} ${building.id}`}
      image={replaceMapKey(building.photo) || ''}
      label={isSubcontractor ? label : null}
      isHighlighted={isContractor}
    >
      {`${building.street} ${building.streetNumber}`}
    </ImageCard>
  ) : null;
};

export function Buildings() {
  const t = useTranslation();
  const user = useUser();
  const query = useApiQuery('getBuildings');
  const { data: subcontractorStatus } = useApiQuery('isSubcontractor');

  const { userBuildings, contractorBuildings } = query.isSuccess
    ? query.data.reduce(
        (
          acc: { userBuildings: Building[]; contractorBuildings: Building[] },
          building: Building
        ) => {
          if (building.company_id === user?.company_id) {
            acc.userBuildings.push(building);
          } else {
            acc.contractorBuildings.push(building);
          }
          return acc;
        },
        { userBuildings: [], contractorBuildings: [] }
      )
    : { userBuildings: [], contractorBuildings: [] };

  const hasFavorites = query.data?.some((building: any) => building.isFavorite);

  const [allIsActive, setAllIsActive] = useState<boolean>(true);
  const [filteredBuildings, setFilteredBuildings] = useState<Building[]>([]);
  const [mapVisibleBuildings, setMapVisibleBuildings] = useState<Building[]>(
    []
  );
  const [filter, setFilter] = useState<'all' | 'user' | 'contractor'>('all');

  useEffect(() => {
    const baseBuildings =
      filter === 'user'
        ? userBuildings
        : filter === 'contractor'
        ? contractorBuildings
        : [...userBuildings, ...contractorBuildings];

    setFilteredBuildings(
      allIsActive
        ? baseBuildings
        : baseBuildings.filter((building: any) => building.isFavorite)
    );
  }, [filter, allIsActive, query.data]);

  const buildingsToDisplay = filteredBuildings.filter((building) =>
    mapVisibleBuildings.some((visible) => visible.id === building.id)
  );

  const buildingsWithoutValidAddress = buildingsToDisplay.filter(
    (building) => building.latitude === null && building.longitude === null
  );

  const buildingsWithValidAddress = buildingsToDisplay.filter(
    (building) => building.latitude !== null && building.longitude !== null
  );

  return (
    <Page pageTitle={t.buildings}>
      <ToggleFilter
        leftIsActive={allIsActive}
        setLeftIsActive={setAllIsActive}
        leftToggleText={t.all}
        rightToggleText={t.favorites}
      />

      <Stack gap={space[4]}>
        {query.isLoading && <Loading />}
        {query.isSuccess && (
          <>
            {query.data.length > 0 && (
              <BuildingsMap
                buildings={filteredBuildings}
                allIsActive={allIsActive}
                onVisibleBuildingsChange={setMapVisibleBuildings}
              />
            )}
            {subcontractorStatus?.isSubcontractor && (
              <Dropdown
                value={filter}
                onChange={(e) =>
                  setFilter(e.target.value as 'all' | 'user' | 'contractor')
                }
              >
                <option value="all">{t.allBuildings}</option>
                <option value="user">{t.yourBuildings}</option>
                <option value="contractor">{t.contractorBuildings}</option>
              </Dropdown>
            )}

            <Stack gap={space[2]}>
              {buildingsWithValidAddress.length > 0 ? (
                buildingsWithValidAddress.map((building) =>
                  renderBuildingCard(
                    building,
                    allIsActive,
                    t,
                    replaceMapKey,
                    building.company_id !== user?.company_id,
                    subcontractorStatus?.isSubcontractor || false
                  )
                )
              ) : (
                <Hint>{t.noBuildingsVisible}</Hint>
              )}
              {hasFavorites || allIsActive ? null : (
                <Hint>{t.noFavoritesChoosen}</Hint>
              )}
            </Stack>
            {buildingsWithoutValidAddress.length > 0 && (
              <>
                <Title>{t.buildingsWithoutValidAddress}</Title>
                {buildingsWithoutValidAddress.map((building) =>
                  renderBuildingCard(
                    building,
                    allIsActive,
                    t,
                    replaceMapKey,
                    building.company_id !== user?.company_id,
                    subcontractorStatus?.isSubcontractor || false
                  )
                )}
              </>
            )}
            <Button as={Link} to="/building" variant="primary">
              {t.addBuilding}
            </Button>
          </>
        )}
        {(user?.role === 'manager' || user?.role === 'admin') && (
          <InviteUsers />
        )}
      </Stack>
    </Page>
  );
}
